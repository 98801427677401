import React from "react";
import {statusTranslator} from "../shared";


const EquipmentView = ({equipment}:{equipment: any}) => {

    return <>
        <div className="rental-item">
            <div className="rental-item__media"><img src={equipment.image}
                                                     alt={equipment.name} />
                <div className="rental-item__links"><a href={`/equipment-details?slug=${equipment.slug}`}>Voir Plus</a><a href={`/enquiry?equipment=${equipment.slug}`}>Demander un devis</a></div>
            </div>
            <div className="rental-item__desc">
                <div className="rental-item__title"><a href={`/equipment-details?slug=${equipment.slug}`}>{equipment.name}</a></div>
                {(equipment.equipmentType === "BOTH")?
                    <div className="rental-item__prices-list">
                        <ul>
                            <li>En vente aussi pour <strong>{Intl.NumberFormat('fr-FR').format(equipment.sellingPrice)}</strong> FCFA</li>
                        </ul>
                    </div>
                    : null}

                <div style={{height: 60, maxHeight:60, overflow: "hidden"}} className="rental-item__price-delivery">
                    <span >{equipment.description}</span>
                </div>
                <div className="rental-item__specifications">
                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                        <li>Immatricule: {equipment.matriculation}</li>
                        <li>Année: {equipment.year}</li>
                        <li>Compteur: {`${equipment.counter} ${equipment.counterLabel}`}</li>
                        <li>Marque/Modèle : {equipment.model?equipment.model.label:'Inconnu'}</li>
                        <li>Chassis: {equipment.chassis}</li>
                        <li>{(equipment.equipmentType === "RENT"?"A LOYER":equipment.equipmentType === "BOTH"?"A LOYER&VENDRE":"A VENDRE")}</li>
                    </ul>
                </div>
            </div>
            <div className="rental-item__price">
                <div className="rental-item__price-box">
                    <div className="rental-item__price-title">
                        <strong>{`Prix de ${equipment.equipmentType === "SALE"?'Vente':'Location'}`} </strong>
                        {/*{(equipment.equipmentType === "BOTH")?<span>{`Vente : ${equipment.sellingPrice} FCFA`}</span>: null}*/}
                    </div>
                    <div className="rental-item__price-current">{Intl.NumberFormat('fr-FR').format(equipment.equipmentType === "SALE"?equipment.sellingPrice:equipment.price)} FCFA</div>
                    {/*<div className="rental-item__price-old">$899</div>*/}
                    {equipment.equipmentType !== "SALE"? <div className="uk-margin-small-top">Par {statusTranslator(equipment.billingType, false)}</div> : null}
                </div>
                <div className="rental-item__price-btn"><a
                    className="uk-button uk-button-secondary uk-button-large"
                    href={`/equipment-details?slug=${equipment.slug}`}>Reserve</a></div>
            </div>
        </div>
    </>
}

export default EquipmentView;