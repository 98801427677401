import React, {useState} from "react";
import HomeCtaSection from "../home/home-cta-section";
import useSWR from "swr";
import {appUrls} from "../../util/api_constant";
import {post, postWithParams} from "../../services/BaseService";
import EquipmentView from "./equipment-view";
import Pagination from "./pagination";
import {processParam} from "../shared";


const Listing = ({}:{}) => {
    const [params, _] = useState<any>(processParam({defaultValue:`cat=all`}));
    const {data} = useSWR<{ data: any[] }>(appUrls.categories, url => post(url, {}));
    const {data:equipments} = useSWR<{ page: number, pageTotal: number, data: any[] }>(`${appUrls.equipments}`, url => postWithParams(url, params, {}));

    const onCategoryCLicked = (cat: any) => {
      processParam({currentValue:`?cat=${cat.slug}&page=1`, defaultValue:`cat=all`})
    }
    const onPageChange = (page: number) => {
        processParam({currentValue: (params && params.cat)?`?cat=${params.cat}&page=${page}`:`?page=${page}`, defaultValue:`cat=all`})
    }
    return <>
        <div className="page-head">
            <div className="page-head__bg" style={{backgroundImage: "url(/assets/img/bg/bg_contacts.jpg)"}}>
                <div className="page-head__content" data-uk-parallax="y: 0, 100">
                    <div className="uk-container uk-container-xlarge">
                        <div className="uk-margin-remove-top page-head__title"> Liste des engins</div>
                        <div className="page-head__breadcrumb">
                            <ul className="uk-breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li><a href="#">Equipments</a></li>
                                <li><span>{params.cat?params.cat:''}</span></li>
                            </ul>
                        </div>
                        {/*<div className="page-head__form">
                            <div className="find-box__form">
                                <form action="#!">
                                    <div
                                        className="uk-grid uk-grid-small uk-flex-middle uk-child-width-1-5@l uk-child-width-1-3@m uk-child-width-1-2@s"
                                        data-uk-grid="" >
                                        <div>
                                            <div className="uk-inline uk-width-1-1"><span className="uk-form-icon"><img
                                                src="/assets/img/icons/truck.svg" alt="truck"
                                                data-uk-svg="" /></span>
                                                <select className="uk-select uk-form-large" name="category">
                                                    <option value="">Select Category</option>
                                                    <option value="">Category 1</option>
                                                    <option value="">Category 2</option>
                                                    <option value="">Category 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-inline uk-width-1-1"><span className="uk-form-icon"><img
                                                src="/assets/img/icons/derrick.svg" alt="derrick"
                                                data-uk-svg="" /></span><input
                                                className="uk-input uk-form-large uk-width-1-1" type="text"
                                                placeholder="Equipment Name" /></div>
                                        </div>
                                        <div>
                                            <div className="uk-inline uk-width-1-1"><span className="uk-form-icon"> <img
                                                src="/assets/img/icons/location.svg" alt="location" data-uk-svg="" /></span><input
                                                className="uk-input uk-form-large uk-width-1-1" type="text"
                                                placeholder="What’s Your Location" /></div>
                                        </div>
                                        <div>
                                            <div className="uk-inline uk-width-1-1"><span className="uk-form-icon"><img
                                                src="/assets/img/icons/calendar.svg" alt="calendar" data-uk-svg="" /></span><input
                                                className="js-calendar uk-input uk-form-large uk-width-1-1" type="text"
                                                placeholder="Rental Duration Period"/></div>
                                        </div>
                                        <div className="uk-flex-last@l">
                                            <div className="uk-inline uk-width-1-1 uk-text-center"><label> <input
                                                className="uk-radio" type="radio" name="delivery" checked onChange={() => {}} /><span>Livrer par SME</span></label><label>
                                                <input className="uk-radio" type="radio" name="delivery" onChange={() => {}}/><span>Non livrer</span></label>
                                            </div>
                                        </div>
                                        <div>
                                            <button className="uk-button uk-button-large uk-width-1-1" type="submit">
                                                <span>Appliquez le filtre</span><img src="/assets/img/icons/arrow.svg"
                                                                                 alt="arrow" data-uk-svg="" /></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div>
        <div className="page-content">
            <div className="uk-section-large uk-container uk-container-xlarge">
                <div className="uk-grid" data-uk-grid="" >
                    <div className="uk-width-1-4@l uk-width-1-4@m uk-width-1-4@xl uk-width-1-1@s">
                        <div className="uk-hidden@l uk-margin-small-bottom">
                            <button className="js-more-filter uk-button uk-button-large uk-width-1-1" type="button">
                                <span>Filters </span><i className="fas fa-sliders-h"></i></button>
                        </div>
                        <aside className="js-filter-content sidebar">
                            <div className="widjet widjet-category">
                                <h4 className="widjet__title">Sous Categories</h4>
                                {
                                    (data && data.data && data.data.length > 0)?
                                        <ul className="list-category">
                                            <li onClick={() => {onCategoryCLicked({slug:'all'})}} className={(params && (params.cat === 'all'))?'uk-background-secondary':''} key={`0`}><span>Toutes les catégories</span></li>
                                            {
                                                data.data.map(d => (<li onClick={() => {onCategoryCLicked(d)}} className={(params && (d.slug === params.cat))?'uk-background-secondary':''} key={`${d.id}`}><span>{d.name}</span></li>))
                                            }
                                        </ul>: <span className="uk-text-center uk-background-secondary"> Aucune Catégorie disponible</span>
                                }
                            </div>
                            {/*<div className="widjet widjet-price">
                                <h4 className="widjet__title">Price <small>(Per Day)</small></h4>
                                <div className="range-box"><input className="js-range-slider" id="range-price"
                                                                  type="text" name="price" />
                                    <div className="range-box__desc"><span>Range from </span><input
                                        id="range-price-from" type="text" /><span>To </span><input id="range-price-to"
                                                                                                 type="text" /></div>
                                </div>
                            </div>
                            <div className="widjet widjet-price">
                                <h4 className="widjet__title">Operating Weight <small>(Ton)</small></h4>
                                <div className="range-box"><input className="js-range-slider" id="range-weight"
                                                                  type="text" name="weight" />
                                    <div className="range-box__desc"><span>Range from </span><input
                                        id="range-weight-from" type="text" /><span>To </span><input id="range-weight-to"
                                                                                                  type="text" /></div>
                                </div>
                            </div>
                            <div className="widjet widjet-price">
                                <h4 className="widjet__title">Digging Depth <small>(Meter)</small></h4>
                                <div className="range-box"><input className="js-range-slider" id="range-meter"
                                                                  type="text" name="meter" />
                                    <div className="range-box__desc"><span>Range from </span><input
                                        id="range-meter-from" type="text" /><span>To </span><input id="range-meter-to"
                                                                                                 type="text" /></div>
                                </div>
                            </div>*/}
                        </aside>
                    </div>
                    <div className="uk-width-3-4@l uk-width-3-4@m uk-width-3-4@xl uk-width-1-1@s">
                        {
                            (equipments && equipments.data && equipments.data.length > 0)?
                                equipments.data.map(e => (<EquipmentView key={`equipment_${e.id}_listing`} equipment={e}/>)):
                                <div>Aucun équipement !</div>
                        }

                        {/*<div className="rental-item">
                            <div className="rental-item__media"><img src="/assets/img/rental-item-1.jpg"
                                                                     alt="Standard Excavator" />
                                <div className="rental-item__links"><a href="#">Voir Plus</a><a href="#">Demander un devis</a></div>
                            </div>
                            <div className="rental-item__desc">
                                <div className="rental-item__title">Standard Excavator</div>
                                <div className="rental-item__prices-list">
                                    <ul>
                                        <li>$125 / Day</li>
                                        <li>$425 / Week</li>
                                        <li>$1350 / Month</li>
                                    </ul>
                                </div>
                                <div className="rental-item__price-delivery"><span>Two Way Delivery: $200</span></div>
                                <div className="rental-item__specifications">
                                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                                        <li>Brand: Wacker Neuson</li>
                                        <li>Manufacture Year: 2019</li>
                                        <li>Digging Depth: 4.3 Meter</li>
                                        <li>Model: EZ17</li>
                                        <li>Operating Weight: 1.7 Ton</li>
                                        <li>Maximum Reach: 4 Meter</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rental-item__price">
                                <div className="rental-item__price-box">
                                    <div className="rental-item__price-title"><strong>Total Rental Price </strong><span>Incl. Taxes</span>
                                    </div>
                                    <div className="rental-item__price-current">$750</div>
                                    <div className="rental-item__price-old">$899</div>
                                </div>
                                <div className="rental-item__price-btn"><a
                                    className="uk-button uk-button-secondary uk-button-large"
                                    href="/equipment-details">Reserve</a></div>
                            </div>
                        </div>
                        <div className="rental-item">
                            <div className="rental-item__media"><img src="/assets/img/rental-item-2.jpg"
                                                                     alt="Mini Excavator Upto 5T" />
                                <div className="rental-item__links"><a href="#">Voir Plus</a><a href="#">Demander un devis</a></div>
                            </div>
                            <div className="rental-item__desc">
                                <div className="rental-item__title">Mini Excavator Upto 5T</div>
                                <div className="rental-item__prices-list">
                                    <ul>
                                        <li>$125 / Day</li>
                                        <li>$425 / Week</li>
                                        <li>$1350 / Month</li>
                                    </ul>
                                </div>
                                <div className="rental-item__price-delivery"><span>Two Way Delivery: $200</span></div>
                                <div className="rental-item__specifications">
                                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                                        <li>Brand: Wacker Neuson</li>
                                        <li>Manufacture Year: 2019</li>
                                        <li>Digging Depth: 4.3 Meter</li>
                                        <li>Model: EZ17</li>
                                        <li>Operating Weight: 1.7 Ton</li>
                                        <li>Maximum Reach: 4 Meter</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rental-item__price">
                                <div className="rental-item__price-box">
                                    <div className="rental-item__price-title"><strong>Total Rental Price </strong><span>Incl. Taxes</span>
                                    </div>
                                    <div className="rental-item__price-current">750 000 FCFA</div>
                                    <div className="rental-item__price-old">$899</div>
                                </div>
                                <div className="rental-item__price-btn"><a
                                    className="uk-button uk-button-secondary uk-button-large"
                                    href="/equipment-details">Reserve</a></div>
                            </div>
                        </div>
                        <div className="rental-item">
                            <div className="rental-item__media"><img src="/assets/img/rental-item-3.jpg"
                                                                     alt="Excavator With Breaker" />
                                <div className="rental-item__links"><a href="#">Voir Plus</a><a href="#">Demander un devis</a></div>
                            </div>
                            <div className="rental-item__desc">
                                <div className="rental-item__title">Excavator With Breaker</div>
                                <div className="rental-item__prices-list">
                                    <ul>
                                        <li>$125 / Day</li>
                                        <li>$425 / Week</li>
                                        <li>$1350 / Month</li>
                                    </ul>
                                </div>
                                <div className="rental-item__price-delivery"><span>Two Way Delivery: $200</span></div>
                                <div className="rental-item__specifications">
                                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                                        <li>Brand: Wacker Neuson</li>
                                        <li>Manufacture Year: 2019</li>
                                        <li>Digging Depth: 4.3 Meter</li>
                                        <li>Model: EZ17</li>
                                        <li>Operating Weight: 1.7 Ton</li>
                                        <li>Maximum Reach: 4 Meter</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rental-item__price">
                                <div className="rental-item__price-box">
                                    <div className="rental-item__price-title"><strong>Total Rental Price </strong><span>Incl. Taxes</span>
                                    </div>
                                    <div className="rental-item__price-current">$750</div>
                                    <div className="rental-item__price-old">$899</div>
                                </div>
                                <div className="rental-item__price-btn"><a
                                    className="uk-button uk-button-secondary uk-button-large"
                                    href="/equipment-details">Reserve</a></div>
                            </div>
                        </div>
                        <div className="rental-item">
                            <div className="rental-item__media"><img src="/assets/img/rental-item-4.jpg"
                                                                     alt="Long Range Excavator" />
                                <div className="rental-item__links"><a href="#">Voir Plus</a><a href="#">Demander un devis</a></div>
                            </div>
                            <div className="rental-item__desc">
                                <div className="rental-item__title">Long Range Excavator</div>
                                <div className="rental-item__prices-list">
                                    <ul>
                                        <li>$125 / Day</li>
                                        <li>$425 / Week</li>
                                        <li>$1350 / Month</li>
                                    </ul>
                                </div>
                                <div className="rental-item__price-delivery"><span>Two Way Delivery: $200</span></div>
                                <div className="rental-item__specifications">
                                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                                        <li>Brand: Wacker Neuson</li>
                                        <li>Manufacture Year: 2019</li>
                                        <li>Digging Depth: 4.3 Meter</li>
                                        <li>Model: EZ17</li>
                                        <li>Operating Weight: 1.7 Ton</li>
                                        <li>Maximum Reach: 4 Meter</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rental-item__price">
                                <div className="rental-item__price-box">
                                    <div className="rental-item__price-title"><strong>Total Rental Price </strong><span>Incl. Taxes</span>
                                    </div>
                                    <div className="rental-item__price-current">$750</div>
                                    <div className="rental-item__price-old">$899</div>
                                </div>
                                <div className="rental-item__price-btn"><a
                                    className="uk-button uk-button-secondary uk-button-large"
                                    href="/equipment-details">Reserve</a></div>
                            </div>
                        </div>
                        <div className="rental-item">
                            <div className="rental-item__media"><img src="/assets/img/rental-item-5.jpg"
                                                                     alt="Special Attachment Excavator" />
                                <div className="rental-item__links"><a href="#">Voir Plus</a><a href="#">Demander un devis</a></div>
                            </div>
                            <div className="rental-item__desc">
                                <div className="rental-item__title">Special Attachment Excavator</div>
                                <div className="rental-item__prices-list">
                                    <ul>
                                        <li>$125 / Day</li>
                                        <li>$425 / Week</li>
                                        <li>$1350 / Month</li>
                                    </ul>
                                </div>
                                <div className="rental-item__price-delivery"><span>Two Way Delivery: $200</span></div>
                                <div className="rental-item__specifications">
                                    <ul className="uk-list uk-list-disc uk-column-1-2@xl uk-column-1-2@s">
                                        <li>Brand: Wacker Neuson</li>
                                        <li>Manufacture Year: 2019</li>
                                        <li>Digging Depth: 4.3 Meter</li>
                                        <li>Model: EZ17</li>
                                        <li>Operating Weight: 1.7 Ton</li>
                                        <li>Maximum Reach: 4 Meter</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="rental-item__price">
                                <div className="rental-item__price-box">
                                    <div className="rental-item__price-title"><strong>Total Rental Price </strong><span>Incl. Taxes</span>
                                    </div>
                                    <div className="rental-item__price-current">$750</div>
                                    <div className="rental-item__price-old">$899</div>
                                </div>
                                <div className="rental-item__price-btn"><a
                                    className="uk-button uk-button-secondary uk-button-large"
                                    href="/equipment-details">Reserve</a></div>
                            </div>
                        </div>*/}
                        {
                            (equipments && equipments.data && equipments.data.length > 0)?
                                <div><Pagination page={(params && params.page)?+params.page:1} pageTotal={equipments.pageTotal} onChange={onPageChange}/></div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
        <HomeCtaSection/>
    </>
}
export default Listing;